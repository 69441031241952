<template>
  <div class="page">
    <van-config-provider :theme-vars="themeVars">
      <van-tabs v-model:active="state.activeTab">
        <van-tab title="包时会员">
          <Subscribe />
        </van-tab>
        <van-tab title="按量付费">
          <Recharge />
        </van-tab>
      </van-tabs>
    </van-config-provider>
  </div>
</template>

<script>
import {
  ActionSheet,
  ActionBar,
  ActionBarButton, Empty, Image as VanImage, Tab, Tabs
} from "vant";
import { reactive } from "@vue/runtime-core";
import Recharge from "./Recharge";
import Subscribe from "./Subscribe";
export default {
  props: {
    name: {
      type: String,
      require: false,
    },
  },
  components: {
    Subscribe,
    Recharge,
    [ActionSheet.name]: ActionSheet,
    [ActionBar.name]: ActionBar,
    [ActionBarButton.name]: ActionBarButton,
    [Empty.name]: Empty,
    [VanImage.name]: VanImage,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  setup() {
    const state = reactive({
      activeTab: 0,
    });

    const themeVars = {
      tabsBottomBarColor: "#7232dd"
    };

    return { state, themeVars };
  },
};
</script>
