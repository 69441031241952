<template>
  <div>
    <van-loading v-if="state.loading" class="mt-5" color="#7232dd" size="36" vertical>
      {{ state.msg }}
    </van-loading>
    <van-empty v-else-if="state.error" image="error" :description="state.msg" />
    <div v-else class="content">
      <van-cell-group>
        <template #title>
          <div class="d-flex justify-content-between">
            <span>请选择订阅时长</span>
            <span>{{ currentBalance }}</span>
          </div>
        </template>
        <div class=" my-2 clickarea">
          <template v-for="(item, index) of prices" :key="index">
            <div v-if="item.price" class="sku d-flex flex-wrap justify-content-between" :class="form.sku_id == index ? 'active' : ''"
              @click="form.sku_id = index">
              <div>
                <div class="token">{{ item.title }}</div>
                <div class="desc"><van-tag color="#7232dd" plain>{{ item.desc }}</van-tag></div>
              </div>
              <div class="price">{{ item.price }}元</div>
            </div>
          </template>
          <template v-if="!prices.length">
            <div class="text-center">暂无可用付费选项</div>
          </template>
        </div>
      </van-cell-group>
      <div class="text-center text-muted mt-3 small">
        虚拟商品不支持退款<br />
        订阅模式不包含ChatGPT4模型<br />
        免费获得Token, 发送 "海报" 给公众号, 获取详情
      </div>
      <van-action-bar>
        <van-action-bar-button type="danger" color="#7232dd" text="立即订阅" loading-text="请稍候..." @click="handleRecharge" />
      </van-action-bar>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import {
  ActionBar,
  ActionBarButton, Empty, Toast, Tag, Tab, Tabs
} from "vant";
import { onMounted, ref, reactive, toRaw } from "@vue/runtime-core";
import { setDocumentTitle } from "@/utils/domUtil";
import { getSubscribePrices, getTokenBalance, subscribeMemberPlan } from "@/api/nijibot.service";
import { signaturePack } from "@/api/wechat.service";
import { time2datetime } from "@/utils/util";

export default {
  components: {
    [ActionBar.name]: ActionBar,
    [ActionBarButton.name]: ActionBarButton,
    [Empty.name]: Empty,
    [Tag.name]: Tag,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  setup() {
    const state = reactive({
      loading: true,
      error: false,
      activeTab: 0,
      msg: "请稍候",
      signaturePageStep: 'wait',  // wait待执行, success成功(仅此状态可以发起微信支付), failed失败
    });
    const form = reactive({
      sku_id: 0,
    });

    const showErrorMessage = (msg) => {
      state.error = true;
      state.msg = msg;
    }

    const signaturePage = async () => {
      try {
        const url = /(Android)/i.test(navigator.userAgent)
          ? location.href.split("#")[0]
          : window.entryUrl;
        const { result } = await signaturePack(url);
        wx.config({
          appId: result.appId,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature,
          jsApiList: ["hideOptionMenu", "closeWindow", "chooseWXPay"],
        });
        wx.ready(() => {
          state.signaturePageStep = "success";
          wx.hideOptionMenu();
        });
        wx.error((res) => {
          state.signaturePageStep = "failed";
          showErrorMessage(res.errMsg);
        });
      } catch (error) {
        state.signaturePageStep = "failed";
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        showErrorMessage(msg ? msg : "前端脚本异常/页面签名失败");
        if (!msg) console.log(error);
      }
    };

    const prices = reactive([]);
    onMounted(async () => {
      setDocumentTitle('尼基波特');
      signaturePage();
      try {
        const { result } = await getSubscribePrices();
        Object.assign(prices, JSON.parse(result.prices));
        loadBalance();
        state.loading = false;
      } catch (error) {
        state.loading = false;
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        showErrorMessage(msg ? msg : "未知错误");
      }
    });

    const currentBalance = ref('');
    const loadBalance = async () => {
      try {
        const { result } = await getTokenBalance();
        const currentTime = parseInt(new Date().getTime() / 1000);
        if (currentTime > result.member_plan_expired_at) {
          currentBalance.value = '-';
        } else {
          currentBalance.value = time2datetime(result.member_plan_expired_at * 1000);
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        showErrorMessage(msg ? msg : "未知错误");
      }
    }

    const handleRecharge = async () => {
      if (form.sku_id < 0) {
        return Toast("请选择SKU");
      }
      try {
        Toast.loading({
          message: '请稍候...',
          forbidClick: true,
          duration: 0,
        });
        const { result } = await subscribeMemberPlan({ ...toRaw(form) });
        wx.chooseWXPay({
          timestamp: result.timeStamp,
          nonceStr: result.nonceStr,
          package: result.package,
          signType: result.signType,
          paySign: result.paySign,
          success: () => {
            Toast.success({
              message: "支付成功",
              forbidClick: true,
              overlay: true,
              duration: 0
            });
            setTimeout(() => {
              wx.closeWindow();
            }, 4000);
          },
          error: (res) => {
            showErrorMessage(res.errMsg ? res.errMsg : "支付失败");
            setTimeout(() => {
              wx.closeWindow();
            }, 4000);
          },
          cancel: () => {
            showErrorMessage("您已取消支付");
            setTimeout(() => {
              wx.closeWindow();
            }, 4000);
          },
        });
      } catch (error) {
        Toast.clear();
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        showErrorMessage(msg ? msg : "未知错误");
      }
    }

    const themeVars = {
      tabsBottomBarColor: "#7232dd"
    };

    return { state, form, prices, currentBalance, handleRecharge, themeVars };
  },
};
</script>

<style scoped>
.content {
  padding-bottom: 100px;
}

.clickarea {
  padding: 16px;
}

.clickarea .sku {
  width: 100%;
  height: 80px;
  border: 2px solid #e5e3ea;
  border-radius: 10px;
  margin: 0 0 15px 0;
  padding: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.clickarea .sku .token {
  font-size: 18px;
  font-weight: bold;
  color: #7232dd;
}

.clickarea .sku .price {
  line-height: 55px;
}

.clickarea .sku.active {
  background-color: #be99ff4d;
  border: 3px solid #7232dd;
}
</style>