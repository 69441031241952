import ApiService from "./api.service";

export function getOrderDetail(trade_no) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/nijibot/order", { trade_no })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

// 微信支付
export function preparePay(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/nijibot/preparePay", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

// Token余额支付
export function pay(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/nijibot/pay", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getTokenBalance() {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/nijibot/balance")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getAwardList() {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/nijibot/awardList")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function rechargeToken(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/nijibot/recharge", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function subscribeMemberPlan(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/nijibot/subscribe", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function refundOrder(trade_no) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/nijibot/refund", { trade_no })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getPrices() {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/nijibot/prices")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getSubscribePrices() {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/nijibot/subscribePrices")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getTemplates(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/nijibot/templates", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getRoles() {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/nijibot/roles")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function getSettings() {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/nijibot/settings")
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function updateSettings(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/nijibot/updateSettings", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
